import {
  Button,
  Flex,
  IconButton,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import Link from "next/link";

import MenuIcon from "../chakraIcons/MenuIcon";
import { GuestLinks, LoggedInLinks } from "../data/NavbarData";

const Navbar = ({ loggedIn, certified }) => {
  return (
    <nav>
      <Flex py={4} pr={4} pl={8} justify={"space-between"}>
        <ChakraLink as={Link} href="/">
          <Button variant={"ghost"} mr={4}>
            <Text fontSize="2xl" color={"baseLight"} fontFamily="gotham-black">
              RENT&nbsp;
            </Text>
            <Text fontSize="2xl" color={"baseLight"} fontFamily="gotham-book">
              SENTER
            </Text>
          </Button>
        </ChakraLink>

        <Flex display={["none", "none", "none", "flex"]} w={"100%"} pl={4}>
          <Stack align={"center"} direction={["column", "row"]} spacing={8}>
            {loggedIn ? (
              <>
                {LoggedInLinks?.length > 0 &&
                  LoggedInLinks.map((link) => {
                    if ((link.certified && certified) || !link.certified) {
                      return (
                        <ChakraLink
                          as={Link}
                          key={link.name + link.url}
                          href={link.url}
                        >
                          {link.name}
                        </ChakraLink>
                      );
                    } else {
                      return;
                    }
                  })}
              </>
            ) : (
              <>
                {GuestLinks?.length > 0 &&
                  GuestLinks.map((link) => (
                    <ChakraLink
                      as={Link}
                      key={link.name + link.url}
                      href={link.url}
                    >
                      {link.name}
                    </ChakraLink>
                  ))}
              </>
            )}
          </Stack>
          <Spacer />
          {!loggedIn ? (
            <Stack
              direction={["column", "row"]}
              align={"center"}
              spacing="24px"
            >
              <ChakraLink as={Link} href="/auth/loggin">
                Logg inn
              </ChakraLink>
              <ChakraLink as={Link} href="/registrer">
                <Button>Registrer senter</Button>
              </ChakraLink>
            </Stack>
          ) : (
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                variant="outline"
                icon={<MenuIcon />}
              />
              <MenuList bg={"baseLight"} color={"baseDark"}>
                <ChakraLink as={Link} href="/auth/rediger">
                  <MenuItem
                    display={"flex"}
                    justifyContent={"center"}
                    _hover={{ bg: "lightBlue" }}
                  >
                    Rediger senter
                  </MenuItem>
                </ChakraLink>

                <ChakraLink as={Link} href="/auth/loggut">
                  <MenuItem
                    display={"flex"}
                    justifyContent={"center"}
                    _hover={{ bg: "lightBlue" }}
                  >
                    Logg ut
                  </MenuItem>
                </ChakraLink>
              </MenuList>
            </Menu>
          )}
        </Flex>
        <Flex display={["flex", "flex", "flex", "none"]}>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              variant="outline"
              icon={<MenuIcon />}
            />
            <MenuList bg={"baseLight"} color={"baseDark"}>
              {loggedIn ? (
                <>
                  {LoggedInLinks?.length > 0 &&
                    LoggedInLinks.map((link) => (
                      <ChakraLink
                        as={Link}
                        key={link.name + link.url}
                        href={link.url}
                      >
                        <MenuItem
                          display={"flex"}
                          justifyContent={"center"}
                          _hover={{ bg: "lightBlue" }}
                        >
                          {link.name}
                        </MenuItem>
                      </ChakraLink>
                    ))}
                  <ChakraLink as={Link} href="/auth/rediger">
                    <MenuItem
                      display={"flex"}
                      justifyContent={"center"}
                      _hover={{ bg: "lightBlue" }}
                    >
                      Rediger senter
                    </MenuItem>
                  </ChakraLink>
                  <ChakraLink as={Link} href="/auth/loggut">
                    <MenuItem
                      display={"flex"}
                      justifyContent={"center"}
                      _hover={{ bg: "lightBlue" }}
                    >
                      Logg ut
                    </MenuItem>
                  </ChakraLink>
                </>
              ) : (
                <>
                  {GuestLinks?.length > 0 &&
                    GuestLinks.map((link) => (
                      <ChakraLink
                        as={Link}
                        key={link.name + link.url}
                        href={link.url}
                      >
                        <MenuItem
                          display={"flex"}
                          justifyContent={"center"}
                          _hover={{ bg: "lightBlue" }}
                        >
                          {link.name}
                        </MenuItem>
                      </ChakraLink>
                    ))}
                  <ChakraLink as={Link} href="/auth/loggin">
                    <MenuItem
                      display={"flex"}
                      justifyContent={"center"}
                      _hover={{ bg: "lightBlue" }}
                    >
                      Logg inn
                    </MenuItem>
                  </ChakraLink>
                  <ChakraLink as={Link} href="/registrer">
                    <MenuItem
                      display={"flex"}
                      justifyContent={"center"}
                      _hover={{ bg: "lightBlue" }}
                    >
                      Registrer senter
                    </MenuItem>
                  </ChakraLink>
                </>
              )}
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </nav>
  );
};

export default Navbar;
