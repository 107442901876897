import Router from "next/router";
import { useEffect } from "react";
import useSWR from "swr";

export default function useUser({
  redirectTo = "",
  redirectIfFound = false,
  admin = false,
  userPage = false,
  certifiedRoute = false,
} = {}) {
  const { data: user, mutate: mutateUser } = useSWR("/api/auth/me");

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    if (!redirectTo || !user) return;
    if (userPage && user && user.admin) {
      Router.push("/admin");
    }

    // If admin page and user not admin, redirect
    if (admin && user && !user?.admin) {
      Router.push("/");
    }
    // Reroute on not certified group
    if (certifiedRoute && userPage && user && !user.certified) {
      Router.push("/sertifisering");
    }
    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && user?.isLoggedIn)
    ) {
      if (user?.admin) {
        Router.push("/admin");
      } else {
        Router.push(redirectTo);
      }
    }
  }, [user, redirectIfFound, redirectTo]);

  return { user, mutateUser };
}
