import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Link as ChakraLink,
  Stack,
  Text,
} from "@chakra-ui/react";
import { withIronSessionSsr } from "iron-session/next";
//import { Button } from "@transformlearning/task-lms-packages";
//import styles from "../styles/Home.module.scss";
import ErrorPage from "next/error";
import Head from "next/head";
import Link from "next/link";
import { groq } from "next-sanity";
import { useAuth } from "src/hooks/api";
import useUser from "src/hooks/useUser";
import { sessionOptions } from "src/lib/sessions";

import client from "../client";
import { getKnex } from "../knex";
import BenefitPreview from "../src/components/BenefitPreview";
import CircleImage from "../src/components/CircleImage";
import Footer from "../src/components/Footer";
import Navbar from "../src/components/Navbar";
import NewsSection from "../src/components/NewsSection";
import SimpleCard from "../src/components/SimpleCard";
import TwoSidedCard from "../src/components/TwoSidedCard";
import { BenefitsData, NewsData, StepsData } from "../src/data/HomePageData";
import {
  getClient,
  PortableText,
  urlFor,
  usePreviewSubscription,
} from "../src/lib/sanity";

export const getServerSideProps = withIronSessionSsr(async function (context) {
  const { req, res } = context;

  const sessionUser = req.session.user;
  if (sessionUser) {
    const groupId = req.session.user.groupId;
    const knex = getKnex();
    const certified = await knex
      .select("*")
      .from("certifications")
      .innerJoin("groups", "groups.id", "certifications.groupId")
      .where("groupId", groupId)
      .andWhere("active", true)
      .orderBy("certifications.createdAt", "desc")
      .limit(1);

    if (
      certified.length > 0 &&
      certified[0].status === "NOT_APPROVED" &&
      !certified[0].certified
    ) {
      return {
        redirect: {
          permanent: false,
          destination: "/auth/loggut",
        },
        props: {
          sessionUser,
        },
      };
    }
  }

  const site = await client.fetch(`
  *[_type == "site"][0] {_id,title}
  `);
  const posts = await client.fetch(groq`
      *[_type == "post" && publishedAt < now()] {_id,slug,title,mainImage,publishedAt,body} | order(publishedAt desc)
    `);
  return {
    props: {
      site,
      posts,
    },
  };
}, sessionOptions);

export default function Home({ site, posts }) {
  const { user } = useUser({ userPage: true, redirectTo: "/" });
  return (
    <div className="wrapper">
      <Head>
        <title>{site?.title}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {user && (
        <>
          <header>
            <Navbar loggedIn={user.isLoggedIn} certified={user.certified} />
          </header>
          <main>
            <Container size="2xl">
              <Stack
                direction={["column", "column"]}
                justify={"center"}
                align={"center"}
                spacing={12}
              >
                <Box px={16} py={8}>
                  <Text align="center">
                    Ny løsning lansert 21.11.22. Alle sentre må registrere seg
                    på nytt. E-læringen er flyttet til&nbsp;
                    <a href="https://kurs.rentsenter.no">kurs.rentsenter.no</a>.
                    <br />
                    Alle brukere må registrere seg på nytt med nye senterkoder
                    og gjennomføre e-læringen igjen.
                  </Text>
                </Box>
              </Stack>
            </Container>
            <Container
              bgImage={"url('./home_bg.jpg')"}
              bgSize={"cover"}
              size="2xl"
              py={8}
            >
              <Stack
                direction={["column", "column"]}
                justify={"center"}
                align={"center"}
                spacing={12}
              >
                <Image
                  src={"./rs_logo.svg"}
                  width={60}
                  objectFit="cover"
                  alt="Rent senter logo"
                />
                <Text color={"baseLight"} align="center" fontWeight="bold">
                  gir dine ansatte kompetanse til å forebygge doping og senteret
                  mulighet til å gjennomføre dopingkontroller
                </Text>
                {/** Only show buttons on a user logged inn */}
                {!user?.isLoggedIn && (
                  <Stack
                    direction={["column", "row"]}
                    justify={"center"}
                    align={"center"}
                    wrap={"wrap"}
                    spacing={12}
                  >
                    <Button size={"xl"} variant="ghost">
                      <ChakraLink as={Link} href="/auth/loggin">
                        Logg inn
                      </ChakraLink>
                    </Button>
                    <ChakraLink as={Link} href="/registrer">
                      <Button size={"xl"} variant="with-shadow">
                        Registrer senter
                      </Button>
                    </ChakraLink>
                  </Stack>
                )}
              </Stack>
              {BenefitsData?.length > 0 && (
                <Center>
                  <Stack
                    my={24}
                    p={8}
                    bgColor={"baseLight"}
                    w={"max-content"}
                    direction={["column", "row"]}
                    spacing={12}
                    justify={"center"}
                    align={["center", "flex-start"]}
                    wrap={"wrap"}
                  >
                    {BenefitsData.map((benefit) => (
                      <BenefitPreview benefit={benefit} key={benefit.title} />
                    ))}
                  </Stack>
                </Center>
              )}

              <Container size={"xl"}>
                <Heading fontSize={"2xl"} color={"baseLight"}>
                  Hvordan bli et Rent Senter?
                </Heading>
                <Stack
                  direction={"row"}
                  justify={"center"}
                  my={4}
                  spacing={8}
                  align={["center", "center"]}
                  shouldWrapChildren
                  wrap={"wrap"}
                >
                  {StepsData.map((step) => (
                    <TwoSidedCard key={step.title} step={step}></TwoSidedCard>
                  ))}
                </Stack>
              </Container>
            </Container>
            {posts?.length > 0 && (
              <NewsSection
                title={"Siste nytt"}
                news={posts.slice(0, 3)}
                linkLable={"Alle nyheter"}
                linkHref={"/nyheter"}
              />
            )}
            <Flex justify={"center"} align={"center"} py={12}>
              <ChakraLink as={Link} href="/registrer">
                <Button size={"xl"} variant="with-shadow">
                  Registrer senter
                </Button>
              </ChakraLink>
            </Flex>
          </main>
          <Footer />
        </>
      )}
    </div>
  );
}
