const rentsenterUrl = process.env.NEXT_PUBLIC_ELEARNING_LINK;

export const GuestLinks = [
  {
    name: "E-læring",
    url: rentsenterUrl,
  },
  {
    name: "Hvorfor",
    url: "/why",
  },
  {
    name: "Pris",
    url: "/priser",
  },
  {
    name: "Kart",
    url: "/kart",
  },
  {
    name: "Q&A",
    url: "/faq",
  },
  {
    name: "Kontakt",
    url: "/kontakt",
  },
  {
    name: "Dopingkontakten",
    url: "https://www.antidoping.no/forebygging/dopingkontakten",
  },
];

export const LoggedInLinks = [
  {
    name: "Sertifisering",
    url: "/sertifisering",
    certified: false,
  },
  {
    name: "E-læring",
    url: "/elaring",
    certified: false,
  },
  {
    name: "Ressurser",
    url: "/ressurser",
    certified: true,
  },
  {
    name: "Kurs",
    url: "/kurs",
    certified: true,
  },
  {
    name: "Produkter",
    url: "/produkter",
    certified: true,
  },
  {
    name: "Dopingkontroll",
    url: "/dopingkontroll",
    certified: true,
  },
  {
    name: "Dopingsamtalen",
    url: "/dopingsamtale",
    certified: true,
  },
  {
    name: "Dopingkontakten",
    url: "https://www.antidoping.no/forebygging/dopingkontakten",
    certified: false,
  },
];
